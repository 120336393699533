* {
    margin: 0;
  }
  
  .lets {
    width: 100%;
  }
  
  .scroll-down header {
    transform: translate3d(0, -100%, 0);
    background: rgb(255, 255, 255);
    margin-top:0;
    position: fixed;
  }
  
  
  .scroll-up header {
    background:rgb(255, 255, 255);
    filter: drop-shadow(0 -10px 20px rgb(170, 170, 170));
    position:fixed;
    transform: translate3d(0, 0, 0);
  }
  
  
  .scroll-up header img{
    width:80px;
  }
  .scroll-up header .menu .hamburger{
    outline-color:#000;
  }
  .scroll-up header .menu .hamburger span{
    background:#000;
  }
  .scroll-up header .menu .hamburger span::before{
    background:#000;
  }
  .scroll-up header .menu .hamburger span::after{
    background:#000;
  }
  .scroll-up .outtter header .hamburger.small,
  .scroll-up header .hamburger.small{
    outline-color: #5CA9FF !important;
  }
  .scroll-up .outtter header .hamburger.small span,
  .scroll-up  header .hamburger.small span{
    background:#5CA9FF !important;
  }
  .scroll-up .outtter header .hamburger.small span:before,
  .scroll-up .outtter header .hamburger.small span:after,
  .scroll-up  header .hamburger.small span:before,
  .scroll-up  header .hamburger.small span:after{
    background:#5CA9FF !important;
  }
  .scroll-up .outtter header .hamburger.small.open span,
  .scroll-up  header .hamburger.small.open span{
    background:transparent !important;
  }
  @media(min-width:768px){
    .scroll-up header{
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        width: 90%;
        padding: 0 5%;
        
    }
    .scroll-down header{
        right:unset;
        left:0;
        padding:0 5%;
       
    width:90%;
    }
    .scroll-up .outtter header ul li a{
        color:#5CA9FF;
        
    }
    .scroll-up #root .outtter header .hamburger.small,
    .scroll-up .outtter header .menu .hamburger.small, .scroll-up header .menu .hamburger.big{
        outline-color:#5CA9FF !important;
    }
    .scroll-up .outtter header .hamburger.small span,
    .scroll-up .outtter header .hamburger.small span::before,
    .scroll-up .outtter header .hamburger.small span::after,
    .scroll-up .outtter header .menu .hamburger.small span,
    .scroll-up .outtter header .menu .hamburger.small span::before,
    .scroll-up .outtter header .menu .hamburger.small span::after{
        background:#5CA9FF !important;
        
    }
    .scroll-up .outtter header ul li a:hover{
        color:rgba(26, 21, 109, 1);
        
    }
    .scroll-up .outtter header ul li .active{
        color:rgba(26, 21, 109, 1);
        
    }
    .scroll-up .outtter header ul li .active::before{
        background-color:rgba(26, 21, 109, 1);
        
    }
    .scroll-up .outtter .main li a,
    .scroll-up .outtter .main li span,
    .scroll-up  .main li a,
    .scroll-up  .main li span{
      color:#5CA9FF !important;
    }
    
    .scroll-up .outtter .main li .active:before{
      background:#5CA9FF !important;
    }
    .scroll-up .outtter .main li .dropdown li a,
    .scroll-up  .main li .dropdown li a{
      color:#ffffff !important;
    }
  }

  @media(min-width:992px){
    .scroll-up header {
      padding-top:0;
      top:0;
  }
  .scroll-down header{
    top:0;
  }
    .scroll-up header .menu ul li a{
      color:#000;
  }
    .scroll-up header .menu ul li a:hover{
      color:#01996D;
  }
    .scroll-up header .menu ul li:last-child a{
      background-color:#000;
      color:#fff;
  }
    .scroll-up header .menu ul li:last-child a:hover{
      color:#01996D;
  }
  }
  
  .menu-is-open {
    overflow: hidden;
  
  
  }
  
  .menu-is-open header {
    filter: none;
  }


.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
