body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
   url("./fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
  }
@font-face {
  font-family: "AvenirNext-Regular";
  src: local("AvenirNext-Regular"),
   url("./fonts/AvenirNext-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  }
@font-face {
  font-family: "Urbanist-Bold";
  src: local("Urbanist-Bold"),
   url("./fonts/Urbanist-Bold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
  }
